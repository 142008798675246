import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
    loggedIn: boolean;
};

const initialState: InitialState = {
    loggedIn: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<boolean>) {
            state.loggedIn = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
