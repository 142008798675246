/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable radix */
/* eslint-disable security/detect-object-injection */
/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash';
import { StageObject, InboundObject, TripStagesDataObject, NotificationObject, PaginateAPIData, SocketData } from "pages/dashboard/types";

type InitialState = {
  stages: StageObject[];
  trips: InboundObject[];
  tripStages: TripStagesDataObject;
  lastUpdatedTimeStamp: any;
  isNotify: boolean;
  notificationCount: number;
  notificationData: NotificationObject[];
  isLoading: boolean;
  pageNumber: number;
  pageSize: number;
  localPageNumber: number;
};

const initialState: InitialState = {
  stages: [],
  trips: [],
  tripStages: {},
  lastUpdatedTimeStamp: "",
  isNotify: false,
  notificationCount: 0,
  notificationData: [],
  isLoading: true,
  pageNumber: 1,
  pageSize: 100,
  localPageNumber: 0,
};

export const inboundSlice = createSlice({
  name: "inbound",
  initialState,
  reducers: {
    setStagesInbound(state, action: PayloadAction<StageObject[]>) {
      state.stages = action.payload;
    },
    setTripsInbound(state, action: PayloadAction<InboundObject[]>) {
      state.trips = action.payload;
      state.isLoading = false;
    },
    setTripStagesInbound(state, action: PayloadAction<TripStagesDataObject>) {
      state.tripStages = action.payload;
      state.lastUpdatedTimeStamp = new Date().toString();
    },
    toggleSubscription(state, action: PayloadAction<number>) {
      const currentTrips = _.map(state.trips, (trip) => trip.id === action?.payload ? { ...trip, is_subscribed: !trip.is_subscribed } : { ...trip });
      state.trips = currentTrips;
    },
    clearAllNotifications(state) {
      state.notificationData = [];
      state.notificationCount = 0;
    },
    updateActionTaken(state, action: PayloadAction<number>) {
      const currentTripStages = { ...state.tripStages };
      currentTripStages[action.payload].actiontaken = true;
      state.tripStages = currentTripStages;
    },
    increasePageNumber(state) {
      state.pageNumber += 1
    },
    updateTrips(state, action: PayloadAction<PaginateAPIData>) {
      if (state.localPageNumber !== action.payload.page_number) {
        state.trips = [...state.trips, ...action.payload.trips_data];
        state.tripStages = { ...state.tripStages, ...action.payload.trips_stages };
        state.isLoading = false;
        state.localPageNumber = action.payload.page_number;
      }
    },
    updatingInboundSocketData(state, action: PayloadAction<SocketData>) {
      // console.log(action?.payload);
      const stages = [...state.stages];
      const currentTrips = [...state.trips];
      const notiData = [...state.notificationData];
      const currentTripStages = { ...state.tripStages };
      const eventsData = action?.payload?.events;

      _.forEach(eventsData, (event) => {
        // const currStageIx = _.findIndex(stages, (s) => s.name === event.event_type);
        const tId: number = parseInt(event.trip_id) as number;
        const tripIx = _.findIndex(currentTrips, (t) => t.id.toString() === tId.toString());
        const currTripStageObj = currentTripStages[tId];
        if (tripIx === -1) {
          const trip: InboundObject = {
            id: tId,
            trip_id: event.trip_id,
            load_id: event.load_id,
            source: event.source,
            destination: event.destination,
            planned_start: event.planned_start,
            is_subscribed: false,
          };
          currentTrips.push(trip)
          const stageIx = _.findIndex(stages, (stg) => stg.name.toLowerCase() === event.event_type.toLowerCase());
          let stageVals: any = [0, 0, 0, 0, 0, 0];
          const stageTs: Array<string> = ['', '', '', '', '', ''];
          if (stageIx !== -1) {
            stageVals = _.fill(stageVals, 1, 0, stageIx);
            stageVals[stageIx] = event.flag; // event[stageEventMap[event.event_type].flag];
            stageTs[stageIx] = event.event_timestamp; // event[stageEventMap[event.event_type].ts] as string;
          } else {
            stageVals = [0, 0, 0, 0, 0, 0];
          }
          currentTripStages[tId] = {
            status: event.trip_status,
            stage_values: stageVals,
            recommendation: event.recommendation === 'none' ? '' : event.recommendation,
            stage_ts: stageTs,
          }
        } else {
          let stageVals = currTripStageObj ? currentTripStages[tId].stage_values : [];
          const stageTs = currTripStageObj ? currentTripStages[tId].stage_ts : ['', '', '', '', '', ''];
          const stageIx = _.findIndex(stages, (stg) => stg?.name.toLowerCase() === event?.event_type?.toLowerCase());
          if (stageIx !== -1) {
            stageVals = _.fill(stageVals, 1, 0, stageIx);
            stageVals[stageIx] = event?.flag; // event[stageEventMap[event.event_type].flag] as StageValue;
            stageTs[stageIx] = event?.event_timestamp; // event[stageEventMap[event.event_type].ts] as string;
          } else {
            stageVals = [0, 0, 0, 0, 0, 0]
          }
          currentTripStages[tId] = {
            status: event.trip_status,
            stage_values: [...stageVals],
            recommendation: event?.recommendation?.toLowerCase() === 'none' ? '' : event.recommendation,
            stage_ts: [...stageTs],
          }
        }
        const notiObj: NotificationObject = {
          id: new Date().getTime(),
          message: event.event_type.toLowerCase() === "order created" ? `Order Created for Trip - ${event.trip_id}` : `Trip ${event.trip_id} moved to ${event.event_type}`,
          event: {
            trip_id: event.trip_id,
            event_type: event.event_type,
            trip_status: event.trip_status,
          },
        };
        notiData.push(notiObj);
      });
      state.trips = currentTrips;
      state.tripStages = currentTripStages;
      state.lastUpdatedTimeStamp = new Date().toString();
      state.isNotify = true;
      state.notificationCount += eventsData?.length;
      state.notificationData = _.reverse(notiData.slice(-15));
    }
  },
});

// Action creators are generated for each case reducer function
export const { setStagesInbound, setTripsInbound, setTripStagesInbound, updatingInboundSocketData,
  toggleSubscription, clearAllNotifications, updateActionTaken, increasePageNumber, updateTrips } = inboundSlice.actions;

export default inboundSlice.reducer;


// {
//   id: 1682940179894,
//   message: '',
//   event: {
//     trip_id: '49217229',
//     event_type: 'Not Started',
//     trip_status: 'On Time'
//   }
// },
// {
//   id: 1682940179894,
//   message: '',
//   event: {
//     trip_id: '49217229',
//     event_type: 'Not Started',
//     trip_status: 'At Risk'
//   }
// },
// {
//   id: 1682940179894,
//   message: '',
//   event: {
//     trip_id: '49217229',
//     event_type: 'Not Started',
//     trip_status: 'Delayed'
//   }
// }

// const stageEventMap: StageEventMapObject = {
//   'Not Started': {
//     ts: 'stage1_timestamp',
//     flag: 'stage1_flag'
//   },
//   'Loading': {
//     ts: 'stage2_timestamp',
//     flag: 'stage2_flag'
//   },
//   'In Yard': {
//     ts: 'stage3_timestamp',
//     flag: 'stage3_flag'
//   },
//   'On Road': {
//     ts: 'stage4_timestamp',
//     flag: 'stage4_flag'
//   },
//   'Arrived': {
//     ts: 'stage5_timestamp',
//     flag: 'stage5_flag'
//   },
//   'Unloaded': {
//     ts: 'stage6_timestamp',
//     flag: 'stage6_flag'
//   }
// }
