import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

const createRoutineMachineLayer = (props: any) => {
    const { waypoints } = props;

    const instance = L.Routing.control({
        waypoints,
        lineOptions: {
            styles: [{ color: "#ED705E", weight: 3 }], extendToWaypoints: false,
            missingRouteTolerance: 0,
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: true,
        fitSelectedRoutes: true,
        showAlternatives: false,
    });


    return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
