import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "../../../components/Loadable";

const Integrations = Loadable(lazy(() => import("./Integration")));

const adminIntegrations: RouteObject[] = [
    {
        path: "/integrations",
        element: <Integrations />,
    },
];

export default adminIntegrations;
