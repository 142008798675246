import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export type SlideOverProps = {
  children: React.ReactNode;
  closeOnClick: () => void;
  width?: boolean;
  padding?: boolean;
  margin?: boolean;
  zindex?: boolean;
  closeIcon?: boolean;
  backgroundslide?: boolean;
  paddingtop?: boolean;
}

function SlideOver({ children, closeOnClick, width, padding, margin, zindex, closeIcon, backgroundslide, paddingtop }: SlideOverProps) {
  return (
    <div className="relative z-20" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className={`pointer-events-auto relative  ${width ? "w-[70vw]" : "max-w-md w-screen"} `}>
              <div className={`absolute z-10  mr-5 flex cursor-pointer bg-gray-100${zindex ? " rounded-tl-[4px] rounded-bl-[4px] top-0 -left-[24px]  mr-[26px] bg-gray-100" : "rounded-full right-2 top-3"} `}>
                <button type="button" className=" rounded-md focus:outline-none p-1 focus:ring-0 focus:ring-white " onClick={() => closeOnClick()} >

                  {closeIcon ? <div className="text-[#18749C] text-xs -mt-[8px]">Close<ChevronRightIcon className="text-xs" /> </div> : <><span className="sr-only">Close panel</span>
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg></>}
                </button>
              </div>
              <div className={`flex h-full flex-col  bg-white ${paddingtop ? "py-0" : "py-1"} pt-0 shadow-xl`}>
                <div className={`overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white relative ${margin ? "mt-0" : "mt-4"}  flex-1 ${padding ? "px-0" : "px-4 sm:px-6"} ${backgroundslide ? "bg-[#F7F9FC]" : ""}`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

SlideOver.defaultProps = {
  width: false,
  padding: false,
  margin: false,
  zindex: false,
  closeIcon: false,
  backgroundslide: false,
  paddingtop: false,
}

export default SlideOver;