import React from "react";
import { AxiosResponse } from 'axios'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks'
import { ENDPOINTS } from 'constants/constant'
import client from 'client'
import clinetFast from 'clientfast'
// import clientfast from "clientfast"
import {
  setStagesInbound,
  // setTripsInbound,
  // setTripStagesInbound,
  updatingInboundSocketData
} from 'store/slice/inboundSlice'
import { loginUser, LoginAPIResponse } from 'store/slice/authSlice';
import routes, { loginRts } from "./constants/routes.config";
import {
  StagesAPIResponseType,
  // InboundDataAPIResponseType,
  // TripStagesInboundAPIResponseType,
  // TripsInitialStateAPIResponseType,
} from './pages/dashboard/types';


const GlobalRoutes = () => useRoutes(routes);
const LoginRoutes = () => useRoutes(loginRts);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) =>
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

function App() {
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false);
  //  const [eventsUpdated, setEventsUpdated] = React.useState(0);
  const isAuthenticated = useAppSelector((s) => s?.auth?.isAuthenticated);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const fetchStagesData = async () => {
    const response: AxiosResponse<StagesAPIResponseType> = await clinetFast.get(
      `${ENDPOINTS.stages}`
    )
    dispatch(setStagesInbound(response.data.data))
  }

  // const fetchTripsInitialState = async () => {
  //   const response: AxiosResponse<TripsInitialStateAPIResponseType> = await clientfast.get(
  //     `${ENDPOINTS.initalStateTrips}`
  //   )
  //   dispatch(setTripsInbound(response.data.trips_data))
  //   dispatch(setTripStagesInbound(response.data.trip_stages))
  // }

  // const fetchInboundTripsDataDummy = async () => {
  //   const response: AxiosResponse<InboundDataAPIResponseType> = await client.get(
  //     `${ENDPOINTS.inboundTrips}`
  //   )
  //   if (trips.length <= 0) {
  //     dispatch(setTripsInbound(response.data.data))
  //   }
  // }

  // const fetchInboundTripsStagesDummy = async () => {
  //   const response: AxiosResponse<TripStagesInboundAPIResponseType> =
  //     await client.get(`${ENDPOINTS.inboundTripStages}`)
  //   if (_.size(tripStages) <= 0) {
  //     dispatch(setTripStagesInbound(response.data.data))
  //   }
  // }

  React.useEffect(() => {
    const user = localStorage.getItem("user_info");
    const token = localStorage.getItem("access_token");
    if (user && token) {
      const loginResp: LoginAPIResponse = {
        user_info: JSON.parse(user) || {},
        token: localStorage.getItem("access_token") || '',
      }
      dispatch(loginUser(loginResp));
    }
  }, []);

  React.useEffect(() => {
    fetchStagesData()
    // fetchTripsInitialState()
    // fetchInboundTripsDataDummy()
    // fetchInboundTripsStagesDummy()
  }, []);

  React.useEffect(() => {
    const socket = socketIOClient(`${process.env.REACT_APP_SOCKET_URL}`);
    socket.on("updatedTrips", data => {
      // console.log(data);
      // const eventsLen: any = data?.events?.length;
      dispatch(updatingInboundSocketData(data));
      // setEventsUpdated(eventsLen)
      if (window.location.href.toLowerCase().includes('dashboard')) {
        setOpen(true);
      }
    });
  }, []);

  return (
    <Router>
      {
        isAuthenticated ? (
          <GlobalRoutes />
        ) : (
          <LoginRoutes />
        )
      }
      <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success"
          sx={{ width: '100%', background: "rgba(255,255,255,0.9)", color: "black" }}
        >
          Trip updates received
        </Alert>
      </Snackbar>
    </Router>
  );
}

export default App;
