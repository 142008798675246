import { ThemeProvider } from "@emotion/react";
import { Outlet } from "react-router-dom";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import GeneralHeader from "components/GeneralHeader";
import { useAppSelector } from "hooks/store-hooks";
import useGetTheme from "hooks/useGetTheme";

function Layout() {
    const mode = useAppSelector((state) => state.theme.mode);
    const theme = useGetTheme(mode);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <section data-testid="section" className="flex">
                    <div data-testid="Div" className="w-[100%] h-[100vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white">
                        <GeneralHeader />
                        <div data-testid="outletDiv" className="w-[100%] bg-gray-100">
                            <Outlet />
                        </div>
                    </div>
                </section>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Layout;
