/* eslint-disable */
import React, { useState } from 'react'
import { setUser } from "store/slice/userSlice";
import { logoutUser } from 'store/slice/authSlice';
import StarIcon from '@mui/icons-material/Star'
import NotificationsIcon from '@mui/icons-material/Notifications'
import HelpIcon from '@mui/icons-material/Help'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Popover from '@mui/material/Popover';
import LinkIcon from '@mui/icons-material/Link';
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks'
import { useNavigate } from 'react-router-dom';
import USA from '../assets/images/usa.png'
import SubscribedTrips from './SubscribedTrips'
import CountryModal from './CountryModal/CountryModal'
import AppModal from './AppModal';
import Notification from "./Notification";

type HeaderProp = {
  admin: boolean;
  title: string;
}

function Header({ admin, title }: HeaderProp) {
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [notificationSlider, setNotificationSlider] = useState<boolean>(false)
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [appModalShow, setAppModalShow] = useState<boolean>(false)
  const [country, setCountry] = useState<string>("USA")
  const [countryFlag, setCountryFlag] = useState<any>(USA)
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const dispatch = useAppDispatch();

  const { trips, tripStages, isNotify, notificationCount } = useAppSelector((state) => state.inbound);

  const setCountryName = (e: React.MouseEvent, name: string, location: string) => {
    setCountry(name)
    setCountryFlag(location)
  }

  const navigate = useNavigate()

  const routechange = () => {
    const path = '/apps'
    navigate(path)
  }

  const logoutroute = () => {
    dispatch(logoutUser())
    dispatch(setUser(false));
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center w-full">
      <div className="flex items-center w-[50%]">
        <div className="text-xl tracking-[0px] leading-[30px] text-[#1C1C1C] font-semibold">{title}</div>
      </div>
      <div className="flex items-center w-[50%] justify-end px-3 pr-5">
        {
          admin ?

            <div className="float-right flex gap-4">

              <div className="cursor-pointer">
                <LinkIcon sx={{ color: "#003668" }} onClick={() => setAppModalShow(true)} data-testid="adminLink" />
                {
                  appModalShow &&
                  <AppModal setAppModalShow={setAppModalShow} appModalShow={appModalShow} data-testid="adminAppModal" />
                }
              </div>
              <div className="cursor-pointer">
                <AccountCircleIcon sx={{ color: "#003668" }} onClick={handleClick} data-testid="adminUserIcon" />
                {
                  open &&
                  <Popover
                    data-testid="adminPopover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 40,
                      horizontal: 'left',
                    }}
                  >
                    <div onClick={logoutroute} className="flex p-4 text-[#F01F1F] cursor-pointer justify-center align-center items-center"><LogoutIcon fontSize='small' sx={{ marginRight: 1 }} /><span>Logout</span></div>
                  </Popover>
                }
              </div>
            </div>
            :
            <div className="float-right flex gap-4">
              <div onClick={() => setModalShow(true)} className="flex cursor-pointer items-center" data-testid="countryFlag">
                <img className='w-7 h-7' src={countryFlag} alt="USA" />
              </div>
              {
                modalShow &&
                <CountryModal setModalShow={setModalShow} modalShow={modalShow} data-testid="countryModal" setCountryName={(e, name, location) => setCountryName(e, name, location)} />

              }
              <div className="cursor-pointer">
                <LinkIcon sx={{ color: "#003668" }} onClick={() => setAppModalShow(true)} data-testid="linkIcon" />
                {
                  appModalShow &&
                  <AppModal setAppModalShow={setAppModalShow} appModalShow={appModalShow} />
                }
              </div>
              <div className="cursor-pointer">
                <StarIcon sx={{ color: "#003668" }} onClick={() => setShowSlider(true)} data-testid="starIcon" />
                {showSlider && <SubscribedTrips setShowSlider={setShowSlider} trips={trips} tripStages={tripStages} data-testid="subscribedTrips" />}
              </div>
              <div className="cursor-pointer relative flex">
                <div>
                  <NotificationsIcon sx={{ color: "#003668" }} onClick={() => setNotificationSlider(true)} data-testid="notificationIcon" />
                </div>
                {
                  isNotify && (
                    <span className='text-white bg-red-500 text-[8px] rounded-full 
                     min-w-[15px] min-h-[15px] flex items-center justify-center
                    absolute right-[-2px] top-[-4px]'>
                      {notificationCount}
                    </span>
                  )
                }
                {
                  notificationSlider && (
                    <Notification setNotificationSlider={setNotificationSlider} data-testid="notificationSlider" />
                  )
                }
              </div>
              <div className="cursor-pointer">
                <AccountCircleIcon sx={{ color: "#003668" }} onClick={handleClick} data-testid="userIcon" />
                {
                  open &&
                  <Popover
                    data-testid="Popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 40,
                      horizontal: 'left',
                    }}
                  >
                    <div onClick={logoutroute} className="flex p-4 text-[#F01F1F] cursor-pointer justify-center align-center items-center"><LogoutIcon fontSize='small' sx={{ marginRight: 1 }} /><span>Logout</span></div>
                  </Popover>
                }
              </div>
            </div>
        }

      </div>
    </div>
  )
}


export default Header