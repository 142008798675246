/* eslint-disable */
import React from 'react';
import { setUser } from "store/slice/userSlice";
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks';
import { logoutUser } from 'store/slice/authSlice';
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star'
import { useNavigate } from "react-router-dom";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import Notification from "../assets/images/notification-10.png"
import Scai from "../assets/images/Scailogo.png"

function PlatformHeader() {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const { loggedIn } = useAppSelector((state) => state.user);

    const navigate = useNavigate()

    const dispatch = useAppDispatch();

    const routechange = () => {
        const path = '/platform'
        navigate(path)
    }

    const logoutroute = () => {
        dispatch(logoutUser())
        dispatch(setUser(false));
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };

    const id = open ? 'simple-popover' : undefined;

    return (

        <div className="bg-white px-4 py-3 flex w-full">
            <div className="flex items-start w-[50%]">
                <div data-testid="scailogo" onClick={routechange} className="flex items-start text-lg text-[#1C1C1C] font-poppins font-semibold tracking-normal cursor-pointer"><img className='h-[40px] w-[94.52px]' src={Scai} alt="Scai" /></div>
            </div>
            <div className="flex justify-end w-[50%]">
                <div className="float-right items-center align-center flex gap-4">

                    <div className='flex items-center cursor-pointer'><img className="w-5 h-5" src={Notification} alt="Notification" /></div>
                    <div className="cursor-pointer">

                        <AccountCircleIcon sx={{ color: "#003668" }} onClick={handleClick} data-testid="accounticon" />
                        {
                            open &&
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 40,
                                    horizontal: 'left',
                                }}
                            >
                                <div onClick={logoutroute} className="flex text-[#F01F1F] text-sm font-poppins p-4 cursor-pointer justify-center align-center items-center"><LogoutIcon fontSize='small' sx={{ marginRight: 1 }} /><span>Logout</span></div>
                            </Popover>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlatformHeader