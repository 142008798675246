import React, { useState, useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import { Autocomplete, TextField, LinearProgress } from "@mui/material";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TripsTable from 'components/TripsTable'
import clientfast from "clientfast"
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks'
import { ENDPOINTS } from 'constants/constant'
import {
  updateTrips
} from 'store/slice/inboundSlice'
import { PaginateAPIData } from './types'

export type location = {
  label: string;
}

export type locationAPIResponse = {
  data: location[];
}

export default function Dashboard() {
  const { pageNumber, localPageNumber, pageSize, stages, trips, tripStages, lastUpdatedTimeStamp, isLoading } = useAppSelector((state) => state.inbound);
  const dispatch = useAppDispatch();

  const [timestamp, setTimeStamp] = useState<any>("")
  const [bound, setBound] = React.useState<string>("OutBound")
  const [location, setLocation] = useState<location>({ label: "All" })
  const [locationList, setLocationList] = useState<any>([])
  const prevtimestampRef = useRef();

  const handleLocationList = (name: any) => {
    const arr: any[] = []
    arr.push({ label: "All" })
    name.map((num: any) => (
      arr.push({ label: num })
    ));
    setLocationList(arr)
  }

  const fetchTripsStatePaginatedUpdate = async () => {
    const response: AxiosResponse<PaginateAPIData> = await clientfast.get(
      `${ENDPOINTS.initalStateTripsPaginate}?page=${pageNumber}&size=${pageSize}`
    );
    dispatch(updateTrips({ trips_data: response.data.trips_data, trips_stages: response.data.trips_stages, page_number: pageNumber }));
  }

  const fetchLocations = async () => {
    const response: AxiosResponse<locationAPIResponse> = await clientfast.get(
      `${ENDPOINTS.alllocation}`
    )
    setLocationList(response.data.data)
    handleLocationList(response.data.data)
  }
  const handleTag = (e: React.MouseEvent, name: string,) => {
    setBound(name)
  }
  const handleLocation = (e: React.MouseEvent, name: location) => {
    setLocation(name)
  }

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (localPageNumber !== pageNumber) {
      fetchTripsStatePaginatedUpdate();
    }
  }, [pageNumber]);

  useEffect(() => {
    prevtimestampRef.current = lastUpdatedTimeStamp
    setTimeStamp(prevtimestampRef.current ? prevtimestampRef.current : "");
  }, [lastUpdatedTimeStamp]);

  return (
    <div className=''>
      <div className='h-[90.6vh] overflow-hidden'>
        <div className='flex justify-between items-center'>
          <div className=' flex w-[187px] h-[30px] bg-white rounded-[4px] overflow-hidden'><Autocomplete data-testid="dropdown" className=' h-[100%] w-[100%]' disableClearable id="combo-box-demo" isOptionEqualToValue={(option, value) => option.label === value.label} value={location} onChange={(e: any, newValue) =>
            handleLocation(e, newValue)
          } options={locationList} renderOption={(props, option) => (
            <span style={{ fontSize: 12 }} {...props}>
              {option.label}
            </span>
          )} renderInput={(params) => <TextField sx={{
            "& .MuiOutlinedInput-input":
            {
              width: "100%",
              height: 13,
              display: 'flex',
              justifyContent: 'center',
              fontSize: 12,
              fontWeight: 400,
              overflow: 'hidden'
            }
          }} {...params} size="small" />} /></div>
          <div>
            <ButtonGroup className='my-4 rounded-l shadow-none border-none h-[30px]' variant="contained" sx={{ "& .MuiButtonGroup-grouped:not(:last-of-type)": { borderColor: "#E5E5E5" } }} aria-label="outlined primary button group">
              <Button data-testid="inbound-button" onClick={(e) => handleTag(e, "InBound")} className={`normal-case outline-none text-[14px] ${bound === "InBound" ? "bg-[#18749C] hover:bg-[#18749C] border-none font-medium font-poppins" : "bg-[#FFFFFF] hover:bg-[#FFFFFF] font-regular font-poppins text-black border-2 border-solid border-[#E5E5E5]"} `}>InBound</Button>
              <Button data-testid="outbound-button" onClick={(e) => handleTag(e, "OutBound")} className={`normal-case text-[14px] ${bound === "OutBound" ? "bg-[#18749C] hover:bg-[#18749C]" : "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-black border-2 border-solid border-[#E5E5E5]"} `}>OutBound</Button>
            </ButtonGroup>
          </div>

        </div>

        <div className='pb-4'>
          {
            isLoading ?
              <div className=' min-h-[58vh] w-[100%]  flex justify-center align-center items-center'>
                <LinearProgress className="w-60 flex justify-center align-center" />
              </div>
              :
              <TripsTable stages={stages} trips={trips} tripStages={tripStages}
                lastUpdatedTimeStamp={lastUpdatedTimeStamp} timestamp={timestamp} bound={bound} location={location.label} />
          }
        </div>
      </div>
    </div>
  )
}
