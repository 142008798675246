import StarIcon from '@mui/icons-material/Star'
import { InboundObject, TripStagesDataObject } from "pages/dashboard/types";
import { STATUS_COLOR_CHIP_CLASSES } from 'constants/constant';
import SlideOver from './SlideOver'

type SubscribedTripsProp = {
  setShowSlider: (flag: boolean) => void;
  trips: InboundObject[];
  tripStages: TripStagesDataObject;
}


function SubscribedTrips({ setShowSlider, trips, tripStages }: SubscribedTripsProp) {
  const subscribedTrips = trips.filter((trip) => trip.is_subscribed);

  const component = (
    <div>
      <h1 className='text-indigo-800 mb-4 font-semibold text-base'>Subscribed Trips</h1>
      <div className='scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white'>
        {subscribedTrips.map((trips) => (
          <div key={trips.id}>
            <div className='py-4 flex'>
              <StarIcon sx={{ color: '#000080' }} className='p-0.5' />
              <div className="px-2">
                <p className='p-1 text-[0.8rem]  '>Trip ID: {trips.trip_id}</p>
                <p className='p-1 text-[0.8rem] '>Source: {trips.source}</p>
                <p className='p-1 text-[0.8rem]'>
                  Destination: {trips.destination}
                </p>
                <span className={`text-center py-1 px-[15px] w-[80px] rounded-[4px] font-poppins font-regular ml-1 capitalize text-[0.8rem] 
                  ${STATUS_COLOR_CHIP_CLASSES[tripStages[trips.id]?.status?.toLowerCase()]} `}>{tripStages[trips.id].status}</span>
              </div>
            </div>
            <hr />
          </div>
        ))
        }
      </div>
    </div>
  )

  return (
    <SlideOver closeOnClick={() => setShowSlider(false)} zindex >{component}</SlideOver>
  )
}

export default SubscribedTrips
