import { ThemeProvider } from "@emotion/react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { MdOutlineDashboard, MdForum } from "react-icons/md";
// import { RiSettings4Line } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import { IoAnalyticsOutline } from "react-icons/io5";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { MenuItemType } from "components/Sidebar/types";
import { useAppSelector } from "hooks/store-hooks";
import useGetTheme from "hooks/useGetTheme";


function Layout() {
    const mode = useAppSelector((state) => state.theme.mode);
    const theme = useGetTheme(mode);

    const menus: MenuItemType[] = [
        { name: "Admin", link: "/admin", icon: MdOutlineDashboard, margin: false, isBottom: false },
        { name: "Integrations", link: "/integrations", icon: IoAnalyticsOutline, margin: false, isBottom: false },
        // { name: "Setting", link: "/", icon: RiSettings4Line, margin: false },
    ];

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <section data-testid="section" className="flex">
                    <Sidebar menuItems={menus} initialOpenState />
                    <div data-testid="Div" className="w-[100%] bg-gray-100 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white">
                        <Header title="PLATFORM ADMINISTRATION" admin />
                        <div data-testid="outletDiv" className="pl-5 pr-6 py-2 w-[100%] ">
                            <Outlet />
                        </div>
                    </div>
                </section>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Layout;
