import { CardObject } from 'pages/dashboard/types';

function CardItem({ number, stage }: CardObject) {
  return (
    <div className="xl:w-[186px] xl:h-[80px] lg:w-[166px] lg:h-[70px] md:w-[130px] md:h-[50px] sm:[110px] sm:h-[35px] mx-1 my-1   py-3 shadow-[0_3px_6px_[#0000000D]] rounded-[5px] flex flex-col justify-center items-center bg-white">
      <h1 className="text-[#1C1C1C] font-semibold font-black font-poppins text-2xl opacity-100">{number}</h1>
      <p className="text-[#7E7E7E] font-poppins text-sm pt-0">{stage}</p>
    </div>
  );
}

export default CardItem;