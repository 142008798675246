import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ArrowBack from '../../assets/images/arrowback.png'

type FormValueType = {
    emailList: string[];
    recepientName: string;
    defaultMessage: string;
    acknowledgeMe: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) =>
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

function UserActionMail({ mountEmail, ActionTaken }: { mountEmail: () => void; ActionTaken: () => void }) {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const [email, setEmail] = useState<string>("")
    const [emailFlag, setEmailFlag] = useState<boolean>(false)
    const [recepientNameFlag, setRecepientNameFlag] = useState<boolean>(false)
    const [defaultMessageFlag, setDefaultMessageFlag] = useState<boolean>(false)
    const [error, setError] = useState<string>('');
    const [disable, setDisable] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [formValue, setFormValue] = useState<FormValueType>({
        emailList: [],
        recepientName: "",
        defaultMessage: "",
        acknowledgeMe: false
    })
    const { recepientName, defaultMessage, acknowledgeMe } = formValue;

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    function notify() {
        toast.success('Email Sent!', { autoClose: 400, position: toast.POSITION.TOP_RIGHT });
    }
    function handleChange(e: any) {
        const { name } = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFormValue((prevState) => (
            {
                ...prevState,
                [name]: value,
            }
        ))
        if (name === "recepientName" && value.trim() === "") {
            setRecepientNameFlag(true)

        } else if (name === "defaultMessage" && value.trim() === "") {
            setDefaultMessageFlag(true)
        } else {
            setRecepientNameFlag(false)
            setDefaultMessageFlag(false)
        }
    }


    function handleEmailChange(e: any) {
        const enteredMail = e.target.value
        // if (enteredMail.trim() === "") {
        //     setEmailFlag(true)

        // } else {
        //     setEmailFlag(false)

        // }
        if ((regex.test(enteredMail) === false || enteredMail.trim() === "")) {
            setEmail(enteredMail)
            setError("")
            setEmailFlag(true)

        }
        else {
            setEmail(enteredMail)
            setError('')
            setEmailFlag(false)
        }
    }

    const onSubmit = async (data: any) => {
        console.log("erros", errors, formValue.acknowledgeMe)
        if (!errors || data.email.trim() === "" || data.recepientName.trim() === "" || data.defaultMessage.trim() === "") {
            setDisable(true)
        } else {
            notify();
            setDisable(false)
            setOpen(true); ActionTaken(); setTimeout(() => {
                mountEmail();
            }, 1000);
        }
        // if (data.email.length >= 1 && data.recepientName.length >= 1 && data.defaultMessage.length >= 1) {
        //     notify();
        //     setFormValue({
        //         emailList: [],
        //         recepientName: "",
        //         defaultMessage: "",
        //         acknowledgeMe: false
        //     });
        //     setEmail('');
        // }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className='bg-[#F7F9FC] h-full  '>
            <div>
                <div className="text-xs text-[#1C1C1C] opcity-50 p-[20px] font-semibold"> </div>
                <div onClick={() => mountEmail()} className='flex cursor-pointer absolute p-2 z-[1000] top-[5px]  right-[2%] rounded-md drop-shadow-[0_3px_6px_rgba(0, 0, 0, 0.16)] flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular  '>
                    Close <img className='flex mx-1 w-[4px] h-[7px]' src={ArrowBack} alt="close" />
                </div>
            </div>
            <div className='p-5 mx-6 bg-[#FFFFFF]'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-[27px]">
                        <div className="mb-2 flex justify-between">
                            <label htmlFor="recepientName" className="text-sm font-medium text-[#919191]">Recepient Name</label>
                        </div>
                        <input className="text-sm rounded w-full h-[35px] border-2 border-slate-300" type="text"   {...register("recepientName")} onChange={handleChange} placeholder="Enter Name" />
                        {recepientNameFlag && <p className="text-sm mt-3 text-red-500">Enter Recepient Name</p>}
                    </div>


                    <div className="mb-[35px]">
                        <label htmlFor="email" className="mb-2 block font-medium text-sm text-[#919191]">Recipient Email</label>
                        <input className="rounded w-full h-[35px] border-2 border-slate-300 text-sm" type="email" {...register("email")} onChange={handleEmailChange} placeholder="Enter Email" />
                        {emailFlag && <p className="text-sm mt-4 text-red-500">Enter Valid Email </p>}
                    </div>


                    <div>
                        <div className="mb-2 flex justify-between">
                            <label htmlFor="defaultMessage" className="text-sm font-medium text-[#919191]">Default Message</label>
                        </div>
                        <textarea className="text-sm h-[100px] rounded resize-none w-full border-2 border-slate-300" rows={2}   {...register("defaultMessage")} onChange={handleChange} placeholder="Enter Message" />
                    </div>
                    {defaultMessageFlag && <p className="text-sm text-red-500">Enter Message</p>}
                    <div className="mb-10 mt-4 flex items-center">
                        <input type="checkbox" className="text-[#F7901D] w-[0.75rem] h-[0.75rem]" name="acknowledgeMe" checked={acknowledgeMe} onChange={handleChange} />
                        <span><label htmlFor="acknowledgeMe" className="text-sm text-[#919191] ml-2">Acknowledge Me</label></span>
                    </div>
                    <div className="flex justify-end text-xs mr-[30px] mt-[5px]">
                        <button className="rounded  text-[#18749C] bg-[#FFFFFF] border-[#18749C] border-[1px] mr-[10px] h-[40px] w-[90px]" type="submit" onClick={() => mountEmail()}>Cancel</button>
                        {
                            emailFlag || recepientNameFlag || defaultMessageFlag || formValue.defaultMessage.trim() === "" || formValue.recepientName.trim() === "" || formValue.defaultMessage.trim() === "" ?
                                <button className="rounded cursor-not-allowed disabled:opacity-50 text-white  bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]" type="submit" disabled>Send</button>
                                :
                                <button className="rounded text-white bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]" type="submit">Send</button>
                        }

                        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                            autoHideDuration={3000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success"
                                sx={{ width: '100%', color: "white" }}
                            >
                                Mail sent
                            </Alert>
                        </Snackbar>
                    </div>
                </form>
            </div >
        </div>


    )
}
export default UserActionMail