import React, { useState, useEffect } from 'react';
import { Modal } from '@mui/material'
import { AxiosResponse } from 'axios';
import clientFast from 'clientfast';
import { ENDPOINTS } from 'constants/constant';
import { CountryObject, CountryObjectAPIResponseType } from './types';

type CountryModalProp = {
    setModalShow: (flag: boolean) => void;
    modalShow: boolean;
    setCountryName: (e: React.MouseEvent, name: string, location: string) => void;
}



export default function CountryModal({ setModalShow, modalShow, setCountryName }: CountryModalProp) {

    const [countries, setCountries] = useState<CountryObject[]>([])

    const countryData = async () => {
        const response: AxiosResponse<CountryObjectAPIResponseType> = await clientFast.get(`${ENDPOINTS.geography}`);
        setCountries(response.data.data)
    };

    const setModalFalse = (e: React.MouseEvent, name: string, location: string) => {
        setCountryName(e, name, location)
        setModalShow(false)
    }

    useEffect(() => {
        countryData()
    }, [])
    return (
        <Modal open={modalShow} onClose={() => setModalShow(false)}>
            <div data-testid="outerWhiteDiv" className="bg-white absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 p-4 w-400 outline-none">
                <div className="flex  justify-center pr-4 text-base text-black">Select Geography</div>
                <div data-testid="countriesDiv" className="my-6 mx-6 p-2 grid grid-cols-6 gap-8">
                    {
                        countries.map((con) => (
                            <div data-testid="country" className="cursor-pointer" onClick={(e) => setModalFalse(e, con.name, con.location)}><img className="flex justify-center w-full" src={con.location} alt={con.name} /><p className="flex justify-center text-center content-center">{con.name}</p></div>
                        ))
                    }

                </div>
            </div>
        </Modal>

    )
}