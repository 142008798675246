/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Snackbar from '@mui/material/Snackbar'
import OutsideClickHandler from 'react-outside-click-handler'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import editIcon from '../../assets/images/Icon-edit.png'
import ArrowBack from '../../assets/images/arrowback.png'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
 <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
))

type FormValueType = {
 defaultMessage: string
 acknowledgeMe: boolean
}

function UserActionCutQty({
 mountCutQty,
 ActionTaken,
}: {
 mountCutQty: () => void
 ActionTaken: () => void
}) {
 const columnsMail = [
  { data: 'Product' },
  { data: 'Qty Ordered' },
  { data: 'Qty Available' },
  { data: 'Cut Product' },
 ]
 const dataMail = [
  {
   Product: 'product1',
   QO: 1100,
   QA: 1100,
   source: 0,
  },
  {
   Product: 'product2',
   QO: 1400,
   QA: 1000,
   source: 400,
  },
  {
   Product: 'product3',
   QO: 1200,
   QA: 900,
   source: 300,
  },
  {
   Product: 'product4',
   QO: 1600,
   QA: 1600,
   source: 0,
  },
  {
   Product: 'product5',
   QO: 1500,
   QA: 1500,
   source: 0,
  },
  {
   Product: 'product6',
   QO: 1450,
   QA: 1450,
   source: 0,
  },
 ]
 const [rows, setRows] = useState(dataMail)
 const [head, setHead] = useState(columnsMail)
 const [visibity, setVisibity] = useState<any>(10)
 const [disable, setDisable] = useState<boolean>(false)
 const [open, setOpen] = React.useState(false)
 const [defaultMessageFlag, setDefaultMessageFlag] = useState<boolean>(false)
 const [formValue, setFormValue] = useState<FormValueType>({
  defaultMessage: '',
  acknowledgeMe: false,
 })

 const { defaultMessage, acknowledgeMe } = formValue

 const {
  register,
  handleSubmit,
  formState: { errors },
 } = useForm()

 function handleChange(e: any) {
  const { name } = e.target
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

  setFormValue((prevState) => ({
   ...prevState,
   [name]: value,
  }))
  if (name === 'defaultMessage' && value.trim() === '') {
   console.log(
    'message',
    formValue.defaultMessage.trim(),
    formValue.defaultMessage
   )
   setDefaultMessageFlag(true)
  } else {
   setDefaultMessageFlag(false)
  }
 }

 const changeRowsData = (ix: any, value: any) => {
  const currData = [...rows]
  rows[ix].source = value
  setRows(currData)
 }

 const onSubmit = async (data: any) => {
  console.log('erros', errors, formValue.acknowledgeMe)
  if (!errors || data.defaultMessage.trim() === '') {
   setDisable(false)
  } else {
   setDisable(true)
   setOpen(true)
   ActionTaken()
   setTimeout(() => {
    mountCutQty()
   }, 1000)
  }
  // if (data.email.length >= 1 && data.recepientName.length >= 1 && data.defaultMessage.length >= 1) {
  //     notify();
  //     setFormValue({
  //         emailList: [],
  //         recepientName: "",
  //         defaultMessage: "",
  //         acknowledgeMe: false
  //     });
  //     setEmail('');
  // }
 }

 const handleClose = (
  event?: React.SyntheticEvent | Event,
  reason?: string
 ) => {
  if (reason === 'clickaway') {
   return
  }
  setOpen(false)
 }

 return (
  <div className=' mx-4'>
   <div
    onClick={() => mountCutQty()}
    className='flex cursor-pointer absolute p-2 z-[1000] top-[5px]  right-[2%] rounded-md drop-shadow-[0_3px_6px_rgba(0, 0, 0, 0.16)] flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular  '
   >
    Close{' '}
    <img className='flex mx-1 w-[4px] h-[7px]' src={ArrowBack} alt='close' />
   </div>
   <div className='bg-[#FFFFFF] mt-[40px] h-full border-[1px] border-[#EBEBEB] border-solid  py-[40px] px-[10px] border'>
    <div className='p-[20px] flex justify-center'>
     <table className='w-full'>
      {head.map((item, id) => (
       <th className='text-[#919191] text-xs text-left'>{item.data}</th>
      ))}

      <tbody>
       {rows.map((item, i) => (
        <tr className='h-[10px]'>
         <td className='text-[#1C1C1C] text-xs text-left py-[5px]'>
          {item.Product}
         </td>
         <td
          className={`${
           item.QO > item.QA ? 'text-[#F01F1F]' : 'text-[#1C1C1C]'
          } text-xs font-poppins text-left py-[5px]`}
         >
          {item.QO}
         </td>
         <td
          className={`${
           item.QO > item.QA ? 'text-[#F01F1F]' : 'text-[#1C1C1C]'
          } text-xs font-poppins text-left py-[5px]`}
         >
          {item.QA}
         </td>
         {visibity === i ? (
          <OutsideClickHandler
           onOutsideClick={() => {
            setVisibity(10)
           }}
          >
           
           <td className='font-poppins flex items-center text-xs text-left py-[5px]'>
            <input
             className='text-xs w-[54px] h-[16px] rounded-[2px] appearance-none'
             type='text'
             value={item.source}
             onChange={(e) => changeRowsData(i, e.target.value)}
            />
           </td>
          </OutsideClickHandler>
         ) : (
          <td
           className={` ${
            item.QO > item.QA ? 'text-[#F01F1F]' : 'text-[#1C1C1C]'
           } flex items-center text-[#1C1C1C] text-xs text-left  py-[5px]`}
          >
           {item.source}
           <img
            className='absolute right-[90px] cursor-pointer h-[11px] w-[11px] ml-[4px]'
            onClick={() => setVisibity(i)}
            src={editIcon}
            alt='edit_icon'
           />
          </td>
         )}
        </tr>
       ))}
      </tbody>
     </table>
    </div>
    <div>
     <form onSubmit={handleSubmit(onSubmit)}>
      <div className='justify-center rounded-[4px] my-[20px] p-[20px]'>
       <label
        htmlFor='defaultMessage'
        className='text-sm font-medium text-[#919191] '
       >
        Default Message
       </label>
       <textarea
        className='mt-[15px] h-[100px] text-sm rounded resize-none w-full border-2 border-slate-300'
        rows={2}
        {...register('defaultMessage')}
        onChange={handleChange}
        placeholder='Enter Message'
       />
       {defaultMessageFlag && (
        <p className='text-sm text-red-500 mt-2'>Enter Message</p>
       )}
      </div>
      <div className='flex items-center'>
       <input
        type='checkbox'
        className='text-[#F7901D] foucs:ring-inherit foucs:ring-offset-0 w-[0.75rem] h-[0.75rem]'
        name='acknowledgeMe'
       />
       <span>
        <label htmlFor='acknowledgeMe' className='text-sm text-[#7E7E7E] ml-2'>
         Acknowledge Me
        </label>
       </span>
      </div>
      <div className='flex justify-end text-xs mr-[30px] mt-[5px]'>
       <button
        className='rounded-[4px]  text-[#18749C] bg-[#FFFFFF] border-[#18749C] border-solid border-[1px] mr-[10px] h-[40px] w-[90px] duration-500 '
        type='submit'
        onClick={() => mountCutQty()}
       >
        Cancel
       </button>
       {defaultMessageFlag || formValue.defaultMessage.trim() === '' ? (
        <button
         className='rounded-[4px] cursor-not-allowed disabled:opacity-50 text-white bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]'
         type='submit'
         disabled
        >
         Apply
        </button>
       ) : (
        <button
         className='rounded-[4px] text-white bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]'
         type='submit'
        >
         Apply
        </button>
       )}

       <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}
       >
        <Alert
         onClose={handleClose}
         severity='success'
         sx={{ width: '100%', color: 'white' }}
        >
         Completed Successfully
        </Alert>
       </Snackbar>
      </div>
     </form>
    </div>
   </div>
  </div>
 )
}

export default UserActionCutQty
