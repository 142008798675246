import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "../../components/Loadable";

const Admin = Loadable(lazy(() => import("./Admin")));

const adminRoutes: RouteObject[] = [
    {
        path: "/admin",
        element: <Admin />,
    },
];

export default adminRoutes;
