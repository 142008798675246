/* eslint-disable */
import React from 'react';
import { setUser } from "store/slice/userSlice";
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks';
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star'
import { useNavigate } from "react-router-dom";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import tigerlogo from "../assets/images/logo.png"
import Notification from "../assets/images/notification-10.png"


function PlatformFooter() {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const { loggedIn } = useAppSelector((state) => state.user);

    const navigate = useNavigate()

    // const dispatch = useAppDispatch();

    // const routechange = () => {
    //     const path = '/apps'
    //     navigate(path)
    // }

    // const logoutroute = () => {
    //     dispatch(setUser(false));
    //     const path = '/'
    //     navigate(path)
    //     setAnchorEl(null);
    //     setOpen(false)
    // }

    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    //     setOpen(true)
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    //     setOpen(false)
    // };

    const id = open ? 'simple-popover' : undefined;

    return (

        <div className="bg-[#1C1C1C] px-[20px] py-[15px] flex w-full">
            <div className="flex items-start w-[50%]">
                <div className="font-poppins font-regular text-white text-[0.7vw] opacity-100">Tiger Analytics Copyright 2023. All Rights Reserved</div>
            </div>
            <div className="flex justify-end w-[50%]">
                <div className="float-right flex gap-4">
                    <div className="font-poppins font-regular text-white text-[0.7vw] opacity-100">Privacy Policy</div> <div className="font-poppins font-regular text-white text-[0.7vw] opacity-100">|</div> <div className="font-poppins font-regular text-white text-[0.7vw] opacity-100">Terms of Service</div>
                </div>
            </div>
        </div>
    );
}

export default PlatformFooter