import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SidebarProps } from "./types";
import Scai from "../../assets/images/scailogowhite.png"
import tigersidebarlogo from "../../assets/images/footer-tigerlogo.png"

function Sidebar({ menuItems, initialOpenState }: SidebarProps) {
  const [open, setOpen] = useState<boolean>(initialOpenState);

  const navigate = useNavigate()
  const location = useLocation()


  const routechange = () => {
    const path = '/platform'
    navigate(path)
  }

  return (

    <div data-testid="sidebarDiv"
      className={`bg-[#020C17] min-h-screen ${open ? "w-48" : "w-12"
        } duration-500 text-gray-100 `}
    >
      <div className={`py-3 flex justify-end items-end align-end  mr-3 ${open && "mr-[1rem]"}`}>
        {open && <div onClick={routechange} className={`text-lg mx-4 cursor-pointer tracking-widest whitespace-pre ${!open && "opacity-0 overflow-hidden"} `}><img className="" src={Scai} alt="logo" /></div>}
        <HiMenuAlt3
          data-testid="hamburgerIcon"
          size={28}
          className="cursor-pointer"
          onClick={() => setOpen(!open)}
        />
        {/* <div onClick={() => setOpen(!open)} className={` text-lg cursor-pointer ${open ? "-ml-[3.6rem]" : "mr-[1rem] ml-4"}`}>X</div> */}
      </div>
      <div className="mt-4 flex flex-col gap-4 relative h-[90vh]">
        {menuItems?.map((menu, i) => (
          menu?.link === "/chatbot" ?
            <a key={menu?.name} className={` ${menu?.margin && "mt-5"
              } group flex items-center text-sm gap-3.5  font-poppins p-2 ${menu?.link === location.pathname ? "bg-[#FFFFFF0D] " : "bg-black "} ${menu?.isBottom ? "mt-[auto]" : ""}`} rel="noreferrer" href="https://nlpdevreact.z13.web.core.windows.net/" target="_blank">
              <div className="ml-1 "><img src={menu?.icon} className="max-w-none h-5" alt="Insights Pro" /></div>
              <h2
                className={`whitespace-pre ${menu?.link === location.pathname ? "text-[#F7901D] font-medium" : "text-white font-regular"}   ${!open && "opacity-0  overflow-hidden"
                  }`}
              >
                {menu?.name}
              </h2>
            </a>
            :
            <Link
              to={menu?.link === "chatbot" ? "" : menu?.link}
              key={`${menu?.name}-${i + 1}`}
              className={` ${menu?.margin && "mt-5"
                } group flex items-center text-sm gap-3.5  font-poppins p-2 ${menu?.link === location.pathname ? "bg-[#FFFFFF0D] " : "bg-black "} ${menu?.isBottom ? "mt-[auto]" : ""}`}
            >
              <div className="ml-1 ">{React.createElement(menu?.icon, { size: "20", color: menu?.link === location.pathname ? "#F7901D" : "white" })}</div>
              <h2

                className={`whitespace-pre ${menu?.link === location.pathname ? "text-[#F7901D] font-medium" : "text-white font-regular"}   ${!open && "opacity-0  overflow-hidden"
                  }`}
              >
                {menu?.name}
              </h2>
            </Link>
        ))}
        {open ? <div className="flex fixed bottom-2"><div className="flex text-left text-[#9B9898] text-sm ml-[10px] opcity-1 font-poppins"><div>Powered</div> <div className="ml-[5px]">by</div></div> <img className="ml-[10px]" src={tigersidebarlogo} alt="tiger" /></div> : <div className="flex absolute bottom-2"> <img src={tigersidebarlogo} alt="tiger" /></div>}

      </div>
    </div>
  );
}

export default Sidebar;