import { RouteObject, Navigate } from "react-router-dom";
import Layout from "layouts/Layout";
import GeneralLayout from "layouts/GeneralLayout"
import PlatformLayout from "layouts/PlatformLayout"
// import AnalyticsLayout from "layouts/AnalyticsLayout";
import dashboardRoutes from 'pages/dashboard/dashboard.routes';
import userprofileRoutes from 'pages/dashboard/userprofile/userprofile.routes';
import loginRoutes from "pages/login/login.routes";
import appsroutes from "pages/appselector/apps.routes";
import analyticsRoutes from "pages/analytics/dashboard.routes"
import chatbotRoutes from "pages/chatbot/chatbot.routes"
import platformRoutes from "pages/platform/platform.routes"
import LoginCheck from "pages/login/LoginCheck";
import adminRoutes from "pages/admin/admin.routes";
import LoginLayout from "layouts/LoginLayout";
import AdminLayout from "layouts/AdminLayout"
import adminIntegrations from "pages/admin/Integrations/Integration.routes"

const routes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [...loginRoutes],
  },
  {
    element: <Layout />,
    children: [...dashboardRoutes],
  },
  {
    element: <Layout />,
    children: [...userprofileRoutes],
  },
  {
    element: <GeneralLayout />,
    children: [...appsroutes]
  },
  {
    element: <Layout />,
    children: [...analyticsRoutes]
  },
  {
    element: <Layout />,
    children: [...chatbotRoutes]
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
  {
    element: <AdminLayout />,
    children: [...adminRoutes]
  },
  {
    element: <PlatformLayout />,
    children: [...platformRoutes]
  },
  {
    element: <AdminLayout />,
    children: [...adminIntegrations]
  },
];

export const loginRts: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [...loginRoutes],
  },
  {
    path: '*',
    element: <LoginCheck />,
  }
]

export default routes;