import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "components/Loadable";

// const Applications = Loadable(lazy(() => import("./AppSelector")));

const appsroutes: RouteObject[] = [
    {
        path: "/apps",
        // element: <Applications />,
    },
];

export default appsroutes;
