const constants = {
  organizationName: "Tiger Analytics",
};

export const ENDPOINTS = {
  inboundCards: 'inbound_metrics',
  stages: 'events/get_stages',
  inboundTrips: 'inbound_trips',
  inboundTripStages: 'inbound_trip_stages',
  individualTrip: 'single_inbound_trip',
  userProfile: 'userprofile_data',
  notification: 'notification_data',
  geography: 'country/get_country_data',
  application: 'app_data',
  subcategory: 'subcategory_data',
  platformapps: '/apps/get_apps',
  platformfavouritesapps: 'platformfavourites_data',
  platformlogisticsapps: 'platformlogistics_data',
  platformwarehouseapps: 'platformwarehouseapps_data',
  platforminventoryapps: 'platforminventory_data',
  alllocation: "map/get_all_locations",
  initalStateTrips: "events/get_initial_stage",
  initalStateTripsPaginate: "events/get_initial_stage_paginated",
};

export const USERDETAILS = [{
  username: 'John Mathew',
  userid: 9382992,
  gender: 'male',
  dob: '07/22/1989',
  location: 'California,US',
  language: 'English',
  phone: '232 908 1234',
  email: 'johnmathew@tigeranalytics.com'
}];

export const STATUS_COLOR_CHIP_CLASSES: any = {
  "delayed": "bg-[#F01F1F14] opacity-100 text-[#F01F1F]",
  "on-time": "bg-[#05C97F14] opacity-100 text-[#05C97F]",
  "on time": "bg-[#05C97F14] opacity-100 text-[#05C97F]",
  "at-risk": "bg-[#F7B91D1A] opacity-100 text-[#F2B417]",
  "at risk": "bg-[#F7B91D1A] opacity-100 text-[#F2B417]",
  "completed": "bg-[#05C97F14] opacity-100 text-[#05C97F]",
}


export default constants;