import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Autocomplete, TextField, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox'
import ArrowBack from '../../assets/images/arrowback.png'

export type location = {
    label: string
}

type FormValueType = {
    defaultMessage: string;
    acknowledgeMe: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) =>
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

function UserActionAssignDoor({
    mountAssignDoor, ActionTaken
}: {
    mountAssignDoor: () => void; ActionTaken: () => void
}) {
    const options = [{ label: 'Door-1' }, { label: 'Door-2' }, { label: 'Door-3' }]
    const [doorValue, setDoorValue] = useState<location>(options[0])
    const [disable, setDisable] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [defaultMessageFlag, setDefaultMessageFlag] = useState<boolean>(false)
    const [formValue, setFormValue] = useState<FormValueType>({
        defaultMessage: "",
        acknowledgeMe: false
    })

    const { defaultMessage, acknowledgeMe } = formValue;

    function handleChange(e: any) {
        const { name } = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFormValue((prevState) => (
            {
                ...prevState,
                [name]: value,
            }
        ))
        if (name === "defaultMessage" && value.trim() === "") {
            console.log("message", formValue.defaultMessage.trim(), formValue.defaultMessage)
            setDefaultMessageFlag(true)
        } else {
            setDefaultMessageFlag(false)
        }
    }



    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const handleLocation = (e: React.MouseEvent, name: location) => {
        setDoorValue(name)
    }

    const onSubmit = async (data: any) => {
        console.log("erros", errors, formValue.acknowledgeMe)
        if (!errors || data.defaultMessage.trim() === "") {
            setDisable(false)
        } else {

            setDisable(true)
            setOpen(true); ActionTaken(); setTimeout(() => {
                mountAssignDoor();
            }, 1000);
        }
        // if (data.email.length >= 1 && data.recepientName.length >= 1 && data.defaultMessage.length >= 1) {
        //     notify();
        //     setFormValue({
        //         emailList: [],
        //         recepientName: "",
        //         defaultMessage: "",
        //         acknowledgeMe: false
        //     });
        //     setEmail('');
        // }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <div className='bg-[#F7F9FC] h-full'>
            <div className=" text-xs text-[#1C1C1C] opcity-50 p-[10px] font-semibold"> </div>
            <div
                onClick={() => mountAssignDoor()}
                className='flex cursor-pointer absolute p-2 z-[1000] top-[5px]  right-[2%] rounded-md drop-shadow-[0_3px_6px_rgba(0, 0, 0, 0.16)] flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular  '
            >
                Close
                <img className='flex mx-1 w-[4px] h-[7px]' src={ArrowBack} alt='close' />
            </div>
            <div className='p-5 mx-6 mt-6 bg-[#FFFFFF]  flex flex-col '>
                <div>
                    <div className=' flex w-[187px] h-[30px] bg-white rounded-[4px] overflow-hidden'>
                        <Autocomplete
                            data-testid="dropdown"
                            className=' h-[100%] w-[100%]'
                            disableClearable
                            id='combo-box-demo'
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            value={doorValue}
                            onChange={(e: any, newValue) => handleLocation(e, newValue)}
                            options={options}
                            renderOption={(props, option) => (
                                <span style={{ fontSize: 12 }} {...props}>
                                    {option.label}
                                </span>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        '& .MuiOutlinedInput-input': {
                                            width: '100%',
                                            height: 13,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            overflow: 'hidden',
                                        },
                                    }}
                                    {...params}
                                    size='small'
                                />
                            )}
                        />
                    </div>
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='justify-center rounded-[4px] my-[20px] p-[20px]'>
                            <label htmlFor="defaultMessage" className="text-sm font-medium text-[#919191] ">Default Message</label>
                            <textarea className="mt-[15px] text-sm rounded resize-none w-full border-2 border-slate-300" rows={2}  {...register("defaultMessage")} onChange={handleChange} placeholder="Enter Message" />
                            {defaultMessageFlag && <p className="text-sm text-red-500 mt-2">Enter Message</p>}
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" className="text-[#F7901D] w-[0.75rem] h-[0.75rem]" name="acknowledgeMe" />
                            <span><label htmlFor="acknowledgeMe" className="text-sm text-[#7E7E7E] ml-2">Acknowledge Me</label></span>
                        </div>
                        <div className="flex justify-end text-xs mr-[30px] mt-[5px]">

                            <button data-testid="Cancel" className="rounded  text-[#18749C] bg-[#FFFFFF] border-[#18749C] border-[1px] mr-[10px] h-[40px] w-[90px]" type="submit" onClick={() => mountAssignDoor()} >Cancel</button>
                            {
                                defaultMessageFlag || formValue.defaultMessage.trim() === "" ?
                                    <button className="rounded cursor-not-allowed disabled:opacity-50 text-white bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]" type="submit" disabled>Apply</button>
                                    :
                                    <button className="rounded text-white bg-[#18749C] text-[#FFFFFF] ml-[10px] h-[40px] w-[76px]" type="submit" data-testid="Apply">Apply</button>

                            }

                            <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                                autoHideDuration={3000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success"
                                    sx={{ width: '100%', color: "white" }}
                                >
                                    Door Assigned
                                </Alert>
                            </Snackbar>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default UserActionAssignDoor
