import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "../../components/Loadable";

const Chat = Loadable(lazy(() => import("./chatbot")));

const loginRoutes: RouteObject[] = [
    {
        path: "/chatbot",
        element: <Chat />,
    },
];

export default loginRoutes;
