import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "../../components/Loadable";
import Dashboard from "./Dashboard"

// const Dashboard = Loadable(lazy(() => import("./Dashboard")));

const dashboardRoutes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];

export default dashboardRoutes;
