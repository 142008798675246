import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "../../components/Loadable";

const Analytics = Loadable(lazy(() => import("./dashboard")));

const loginRoutes: RouteObject[] = [
    {
        path: "/analytics",
        element: <Analytics />,
    },
];

export default loginRoutes;