/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
// import { AxiosResponse } from 'axios'
// import { ENDPOINTS } from 'constants/constant'
// import { useState, useEffect } from 'react'
// import client from 'client';
// import { NotificationObject, NotificationAPIResponseType } from '../pages/dashboard/userprofile/types'

import { useAppDispatch, useAppSelector } from 'hooks/store-hooks'
// import { NotificationObject } from 'pages/dashboard/types';
import { clearAllNotifications } from 'store/slice/inboundSlice';
import SlideOver from 'components/SlideOver';
import { STATUS_COLOR_CHIP_CLASSES } from "constants/constant";
import moment from 'moment';

function Notification({ setNotificationSlider }: { setNotificationSlider: (flag: boolean) => void }) {
  const dispatch = useAppDispatch();
  const { notificationData } = useAppSelector((state) => state.inbound);

  return (
    <SlideOver closeOnClick={() => setNotificationSlider(false)} zindex padding margin >
      <div className="font-poppins">
        <div className="flex justify-between px-4 py-3 bg-[#F7F9FC] shadow">
          <p className="text-sm font-semibold text-[#1C1C1C] opacity-1 Poppins">Notifications</p>
          <p className="text-sm Poppins text-[#18749C]" onClick={() => {
            dispatch(clearAllNotifications())
          }}>Mark all as read</p>
        </div>

        <div className='flex flex-col h-[87vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white'>
          {
            notificationData.map((noti) => (
              <div key={noti.id} className='border-b border-slate-200'>
                <div className='py-3 px-3'>
                  <div className='flex items-center justify-between '>

                    <div className='flex items-center text-[14px] pb-1'>
                      <div className='bg-[#F7901D] w-[7px] h-[7px] rounded-[50%] '> </div> <div className='ml-[10px] text-sm'>Trip ID:<span className='font-bold'>{noti.event.trip_id}</span> moved to <span className='font-bold'>{noti.event.event_type}</span></div>
                    </div>
                    <div className={`mr-2 text-center p-[2px] px-3 font-poppins text-xs capitalize rounded-[10px] ${STATUS_COLOR_CHIP_CLASSES[noti.event.trip_status?.toLowerCase()]}`}>
                      {noti.event.trip_status}
                    </div>
                  </div>
                  <div className='flex justify-start ml-[16px]'>
                    <div className='text-[#AEAEAF] Poppins text-xs mr-2'>{moment().fromNow()}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className='text-center text-[#18749C] text-sm px-4 py-3'>Older Notifications</div>
      </div>
    </SlideOver>
  )
}

export default Notification;
