import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from 'hooks/store-hooks'
import { loginUser, LoginAPIResponse } from 'store/slice/authSlice';

function LoginCheck() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const user = localStorage.getItem("user_info");
    const token = localStorage.getItem("access_token");
    if (user && token) {
      const loginResp: LoginAPIResponse = {
        user_info: JSON.parse(user) || {},
        token: localStorage.getItem("access_token") || '',
      }
      dispatch(loginUser(loginResp));
    } else {
      navigate("/");
    }
  }, []);
  return (
    <>
    </>
  )
}

export default LoginCheck;
