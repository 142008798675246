/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useMemo, useState, useRef } from "react";
import CardItem from "components/Card/CardItem";
import moment from "moment";
import _, { isEmpty, size } from "lodash";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { InboundObject } from "pages/dashboard/types";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useAppDispatch } from 'hooks/store-hooks'
import { increasePageNumber, toggleSubscription } from 'store/slice/inboundSlice'
import { STATUS_COLOR_CHIP_CLASSES } from "constants/constant";
import { TripsTableProps } from "./types";
import TripsSlider from "./TripsSlider";



function TripsTable({ stages, trips, tripStages, lastUpdatedTimeStamp, timestamp, bound, location }: TripsTableProps) {
  const tableContainerRef = useRef<HTMLInputElement>(null);
  const [tripSliderItemId, setTripSliderItemId] = useState<number>(0);
  const [tabs, setTabs] = useState<string>("all");
  const [sorting, setSorting] = useState<boolean>(false);
  const [tripSlider, setTripSlider] = useState<boolean>(false);

  const lastUpdated = moment(new Date(lastUpdatedTimeStamp)).fromNow();

  const staticDataColumns: MRT_ColumnDef<InboundObject>[] = [
    // {
    //   id: 'id',
    //   accessorKey: 'id',
    //   header: 'ID',
    //   size: 40,
    //   enableColumnFilter: false,
    // },
    {
      id: 'trip_id',
      accessorKey: 'trip_id',
      header: 'Trip ID',
      size: 90,
      muiTableHeadCellFilterTextFieldProps: {},
      Cell: ({ row }) => (
        <div onClick={() => { setTripSlider(true); setTripSliderItemId(row.original.id) }} className="text-[#18749C] font-poppins cursor-pointer">
          {row.original.trip_id}
        </div>
      ),
      enableSorting: sorting,
      enableColumnFilter: false,
    },
    {
      id: 'load_id',
      accessorKey: 'load_id',
      header: 'Load ID',
      size: 90,
      Cell: ({ row }) => (
        <div className="text-[#808080] font-poppins">
          {row.original.load_id}
        </div>
      ),
      enableSorting: sorting,
      enableColumnFilter: false,
    },
    {
      id: 'source',
      accessorKey: 'source',
      header: 'Source',
      size: 90,
      Cell: ({ row }) => (
        <div className="text-[#18749C] font-poppins">
          {row.original.source}
        </div>
      ),
      enableSorting: sorting,
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Source' },

    },
    {
      id: 'destination',
      accessorKey: 'destination',
      header: 'Destination',
      size: 90,
      Cell: ({ row }) => (
        <div className="text-[#808080] font-poppins">
          {row.original.destination}
        </div>
      ),
      enableSorting: sorting,
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Destination' },
    },
    {
      id: 'planned_start',
      accessorKey: 'planned_start',
      header: 'PD-Date',
      size: 120,
      muiTableHeadCellFilterTextFieldProps: { placeholder: 'Date' },
      Cell: ({ row }) => (
        <div className="text-[#808080] font-poppins">
          {moment(row.original.planned_start, 'DD-MM-YYYY').format("DD MMM, YY - hh:mm A")}
        </div>
      ),
      enableSorting: sorting,
    },
    {
      id: 'status',
      accessorKey: undefined,
      header: 'Status',
      size: 70,
      enableSorting: sorting,
      Cell: ({ row }) => {
        const status = tripStages[row.original.id]?.status;
        return (
          <div className={`text-center p-[3px] w-32 font-poppins font-regular capitalize rounded-[4px] ${STATUS_COLOR_CHIP_CLASSES[status?.toLowerCase()]}`}>
            {status}
          </div>
        )
      }

    },
  ];



  const dispatch = useAppDispatch()
  const stageColumns: MRT_ColumnDef<InboundObject>[] = _.map(stages, (stage, index) => ({
    id: stage.name,
    accessorKey: undefined,
    header: stage.name,
    size: 80,
    enableSorting: false,
    enableGlobalFilter: false,
    muiTableBodyCellProps: {
      sx: {
        paddingLeft: index === 0 ? "10px" : "0px",
        paddingRight: index === stages.length - 1 ? "10px" : "0px",
        fontSize: "0.75rem",
      }
    },
    Cell: ({ row }) => {
      const tripStage = tripStages[row.original.id];
      const value = tripStage?.stage_values[index];
      const indexOfNS = tripStage?.stage_values.indexOf(0);
      const stagesLen = tripStage?.stage_values.length;
      const colorClasses = {
        "delayed": "bg-[#F01F1F]",
        "at risk": "bg-[#F7B91D]",
        "at-risk": "bg-[#F7B91D]",
        "completed": "bg-[#05C97F]",
        "default": "bg-white"
      }

      return (
        <div className="flex items-center w-[100%] relative">
          <div className={
            `border w-[50%] ${index === 0 ? "border-transparent " : "border-gray-400"}
            ${indexOfNS !== -1 && indexOfNS < index ? "border-dashed" : ""}`
          } />
          <div className={
            `w-[10px] h-[10px] rounded-[50%] border border-gray-300 
            ${value === 1 ? colorClasses.completed : value === 2 ? colorClasses["at-risk"] : value === 3 ? colorClasses.delayed : colorClasses.default}
            `
          } />
          <div className={
            `border w-[50%] ${index === stagesLen - 1 ? "border-transparent" : "border-gray-400"} 
            ${indexOfNS !== -1 && indexOfNS <= index ? "border-dashed" : ""}`
          } />
        </div>
      )
    }
  }));

  const actionColumns: MRT_ColumnDef<InboundObject>[] = [
    {
      id: 'subscribed',
      accessorKey: 'is_subscribed',
      header: '',
      size: 20,
      enableSorting: false,
      enableGlobalFilter: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div className="flex justify-center cursor-pointer items-center">
          <div onClick={() => dispatch(toggleSubscription(row.original.id))} className="pr-3 cursor-pointer">
            {
              row.original.is_subscribed ? (
                <StarIcon sx={{ color: "#003668", fontSize: "1rem" }} />
              ) : (
                <StarBorderIcon sx={{ color: "#003668", fontSize: "1rem" }} />
              )
            }
          </div>
        </div>
      )
    }
  ];

  const columns = useMemo<MRT_ColumnDef<InboundObject>[]>(
    () => stageColumns.length > 0 ? [
      ...staticDataColumns,
      ...stageColumns,
      ...actionColumns,
    ] : [],
    [staticDataColumns, stageColumns, actionColumns],
  );


  const getFrequency = (array: string[]) => {
    const map: any = {}
    array.forEach((item: string) => {
      if (map[item]) {
        map[item] += 1;
      } else {
        map[item] = 1;
      }
    });
    return map;
  };
  const partitions = _.partition(trips, (trip) => tripStages[trip.id]?.status?.toLowerCase() === "delayed");
  const pinnedSortedTrips = [...partitions[0], ...partitions[1]].filter((trip) => tripStages[trip?.id]?.status.toLowerCase() !== "completed");
  const filteredObject = location === "All" ? pinnedSortedTrips : bound === "InBound" ? pinnedSortedTrips.filter((trip) => trip.destination === location) : pinnedSortedTrips.filter((trip) => trip.source === location)
  const filteredtabObject = tabs === "all" ? filteredObject : filteredObject.filter((trip) => tripStages[trip.id]?.status?.toLowerCase() === "delayed")
  const delayedcount = filteredObject.filter((trip) => tripStages[trip.id]?.status?.toLowerCase() === "delayed").length
  const allcount = filteredObject.length
  const handleMetricsCard = (filteredObject: InboundObject[]) => {
    const arr: any[] = []
    let data: any[] = []
    if (filteredObject && filteredObject?.length > 0 && tripStages && size(tripStages) > 0) {
      // Logic for counting inprogress items
      // arr.push(tripStages[filter.id].stage_values.indexOf(0) === 0 ? "notstarted" : tripStages[filter.id].stage_values.indexOf(0) === 1 ? "loading" : tripStages[filter.id].stage_values.indexOf(0) === 2 ? "inyard" : tripStages[filter.id].stage_values.indexOf(0) === 3 ? "onroad" : tripStages[filter.id].stage_values.indexOf(0) === 4 ? "arrived" : tripStages[filter.id].stage_values.indexOf(0) === 5 ? "unloaded" : "")
      filteredObject.map((filter: any) => (
        arr.push(tripStages[filter.id].stage_values.indexOf(0) === 1 ? "notstarted" : tripStages[filter.id].stage_values.indexOf(0) === 2 ? "loading" : tripStages[filter.id].stage_values.indexOf(0) === 3 ? "inyard" : tripStages[filter.id].stage_values.indexOf(0) === 4 ? "onroad" : tripStages[filter.id].stage_values.indexOf(0) === 5 ? "arrived" : tripStages[filter.id].stage_values.indexOf(0) === -1 ? "unloaded" : "")
      ));
      const obj = getFrequency(arr)
      data = [
        {
          "number": obj.notstarted ? obj.notstarted : 0,
          "stage": "Not Started"
        },
        {
          "number": obj.loading ? obj.loading : 0,
          "stage": "Loading"
        },
        {
          "number": obj.inyard ? obj.inyard : 0,
          "stage": "In Yard"
        },
        {
          "number": obj.onroad ? obj.onroad : 0,
          "stage": "On Road"
        },
        {
          "number": obj.arrived ? obj.arrived : 0,
          "stage": "Arrived"
        },
        {
          "number": obj.unloaded ? obj.unloaded : 0,
          "stage": "Unloaded"
        }
      ]
    }
    return data;
  }

  const cardsData = handleMetricsCard(filteredtabObject)

  return (
    <>
      <div className='flex justify-between'>
        {cardsData.map((card) => (
          <CardItem key={card.stage} number={card.number} stage={card.stage} />
        ))}
      </div>
      <div className="flex mt-4 w-[100%] ">
        <span onClick={() => setTabs("delayed")} className={` font-poppins text-[14px] mr-16 cursor-pointer ${tabs === "delayed" ? "font-semibold text-[#18749C] border-b-2 border-[#18749C]" : "font-regular text-[#1C1C1C] border-grey-600"} `}> Delayed ({delayedcount}) </span>
        <span onClick={() => setTabs("all")} className={` font-poppins text-[14px] cursor-pointer ${tabs === "all" ? "font-semibold text-[#18749C] border-b-2 border-[#18749C]" : "font-regular text-[#1C1C1C] border-grey-600"} `}> All ({allcount}) </span>
        {
          isEmpty(timestamp)
            ?
            ""
            :
            <div className="flex  ml-auto font-poppins font-medium text-[12px] text-[#AEAEAF]"> Last Updated  <span className='ml-1 font-medium text-[#7E7E7E]'>{lastUpdated}</span>  </div>
        }

      </div>
      <div className="relative pt-3">
        {
          tripSlider &&
          <TripsSlider stages={stages} tripStage={tripStages[tripSliderItemId]}
            selectedTrip={_.filter(trips, (t) => t.id.toString() === tripSliderItemId.toString())[0]}
            sliderItemId={tripSliderItemId} tableTrips={filteredtabObject} setShowSlider={setTripSlider}
            tripStatus={tripStages[tripSliderItemId]?.status} setSliderItemId={setTripSliderItemId} />
        }
        <div onClick={() => setSorting(!sorting)} className={`flex  absolute -right-12 top-7 z-10 font-poppins text-[12px] mr-16 cursor-pointer ${sorting === true ? "font-semibold text-[#F7901D]" : "font-regular text-[#1C1C1C] "} `}> <FilterAltOutlinedIcon className="w-[18px] h-[16.8px]" />Sort</div>

        <MaterialReactTable
          columns={columns}
          data={filteredtabObject}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          positionGlobalFilter="right"
          enableSorting
          enablePagination={false}
          enableRowVirtualization
          enableBottomToolbar={false}
          globalFilterFn="contains"
          initialState={{
            density: 'compact',
            showGlobalFilter: true,
          }}
          muiTableHeadCellProps={{
            sx: () => ({
              fontSize: "13px",
              color: "black",
              backgroundColor: "#E9EBF0",
            })
          }}
          muiTableHeadProps={{
            sx: () => ({
              fontSize: "13px",
              color: "black",
              backgroundColor: "#E9EBF0",
            })
          }}
          muiTableBodyCellProps={{
            sx: () => ({
              fontSize: "0.75rem",
            })
          }}
          muiTableBodyRowProps={{
            sx: () => ({
              '&:nth-of-type(odd)': {
                backgroundColor: "white",
              },
              '&:nth-of-type(even)': {
                backgroundColor: "#F7F9FC",
              }
            })
          }}
          muiSearchTextFieldProps={{
            placeholder: 'Search',
            sx: {
              minWidth: '250px', height: 10, borderColor: "#003668", position: 'absolute', left: '4px', top: '-9px',
              "& .MuiOutlinedInput-input":
              {
                background: "white",
                borderColor: 'grey',
                height: 16,
                display: 'flex',
                justifyContent: 'center',
                fontSize: 14
              },
              "& .MuiSvgIcon-root": {
                height: 20,
                width: 20
              },
              "& .MuiButtonBase-root": {
                height: 20,
                width: 20
              }
            },
            variant: 'outlined',
          }}
          muiTableHeadCellFilterTextFieldProps={{
            sx: { width: '100%', backgroundColor: "white", },
            variant: 'outlined',
          }}
          muiTableContainerProps={{
            sx: { maxHeight: '58vh', },
            className: 'scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white',
            ref: tableContainerRef,
            onScroll: (_.debounce(() => {
              if (tableContainerRef && tableContainerRef.current) {
                const target: HTMLInputElement = tableContainerRef.current as HTMLInputElement;
                const difference: number = target.scrollHeight - target.scrollTop - target.clientHeight;
                if (difference <= 50) {
                  dispatch(increasePageNumber());
                }
              }
            }, 1000))
          }}
        />
      </div>
    </>
  );
}

export default TripsTable;