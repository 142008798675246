import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from 'axios';
import { Modal } from '@mui/material'
import clientfast from 'clientfast'
import { PlatformObject, PlatformObjectAPIResponse } from 'pages/platform/types';
import { ENDPOINTS } from 'constants/constant';
// import { AppObject, AppObjectAPIResponseType } from '../pages/appselector/types';
// import sense from '../assets/images/sense.jpg'

type AppModalProp = {
    setAppModalShow: (flag: boolean) => void;
    appModalShow: boolean;

}



export default function AppModal({ setAppModalShow, appModalShow, }: AppModalProp) {

    const [apps, setApps] = useState<PlatformObject[]>([])

    const appData = async () => {
        const response: AxiosResponse<PlatformObjectAPIResponse> = await clientfast.get(`${ENDPOINTS.platformapps}`);
        setApps(response.data.data)
    };

    const navigate = useNavigate()

    const routechange = (e: React.MouseEvent, name: string,) => {
        if (name === "Middle Mile Intelligence") {
            const path = '/dashboard'
            navigate(path)
        } else if (name === "Platform Administration") {
            const path = '/admin'
            navigate(path)
        } else if (name === "Insights Pro") {
            window.open("https://nlpdevreact.z13.web.core.windows.net/", "_blank")
        }

        setAppModalShow(false)
    }


    useEffect(() => {
        appData()
    }, [])

    return (
        <Modal sx={{ height: 420, width: 400, position: "absolute", top: '20%', left: '40%' }} open={appModalShow} onClose={() => setAppModalShow(false)}>
            <div data-testid="outerWhiteDiv" className="bg-white relative top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 p-6 h-full w-full overflow-y-auto scrollbar outline-none">
                <div className='my-2 mb-4'>
                    <div className="flex justify-center p-2 text-lg text-black">Applications</div>
                    <div data-testid="applicationsDiv" className='flex mt-4 flex-col gap-6'>
                        {apps.map((app) => <div onClick={(e) => routechange(e, app.name)}> <div className="flex p-2 mx-4 cursor-pointer"><img className="flex justify-center w-7 h-7" src={app.image} alt="Logo" /><p className="mx-4 flex text-[15px] justify-center items-center text-center content-center">{app.name}</p></div> <hr /></div>)}
                    </div>
                </div>
            </div>
        </Modal>

    )
}