/* eslint-disable */
/* eslint-disable security/detect-object-injection */
import SlideOver from 'components/SlideOver'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks'
import { updateActionTaken } from 'store/slice/inboundSlice'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
    IndividualTripObject,
    StageObject,
    InboundObject,
    TripStagesObject
} from 'pages/dashboard/types'
import moment from 'moment'
import { STATUS_COLOR_CHIP_CLASSES } from 'constants/constant'
import Previous from '../../assets/images/previous.png'
import Next from '../../assets/images/next.png'
import MapSlider from "./MapSlider";
import ArrowBack from '../../assets/images/arrowback.png'
import UserActionMail from './UserActionMail';
import UserActionAssignDoor from './UserActionAssignDoor';
import UserActionAssignTruck from './UserActionAssignTruck';
import UserActionCutQty from './UserActionCutQty';



function TripsSlider({
    stages,
    tableTrips,
    sliderItemId,
    setShowSlider,
    tripStatus,
    selectedTrip,
    tripStage,
    setSliderItemId
}: {
    stages: StageObject[]
    tableTrips: InboundObject[]
    sliderItemId: number
    setShowSlider: (flag: boolean) => void
    tripStatus: string
    selectedTrip: InboundObject
    tripStage: TripStagesObject
    setSliderItemId: (flag: number) => void
}) {
    const { tripStages } = useAppSelector((state) => state.inbound);
    const [trip, setTrip] = useState<IndividualTripObject | null>(null)
    const [showSide, setShowSide] = useState<boolean>(false);
    const [isMapMount, setIsMapMount] = useState<boolean>(false);
    const [isCutQtyMount, setIsCutQtyMount] = useState<boolean>(false);
    const [isEmailMount, setEmailQtyMount] = useState<boolean>(false);
    const [isAssignDoorMount, setIsAssignDoorMount] = useState<boolean>(false);
    const [isAssignTruckMount, setIsAssignTruckMount] = useState<boolean>(false);
    const [actionTaken, setActionTaken] = useState<any>([]);

    const dispatch = useAppDispatch();

    const ActionTaken = () => {
        dispatch(updateActionTaken(sliderItemId))
        // const arr: any = []
        // // const temp: any = {}
        // // temp[sliderItemId] = { actiontaken: true }
        // // arr.push(temp)
        // arr[sliderItemId] = true
        // setActionTaken(arr)
        // console.log("action taken", arr)
        // console.log(sliderItemId + 1, selectedTrip.trip_id)
        // console.log("action taken", tripStage.actiontaken)
    }

    const creteSingleTrip = (id: number) => {
        const tripStage = tripStages[id];
        const indivTrip: IndividualTripObject = {
            id,
            stages: tripStage ? tripStage.stage_values.map((stg, ix) => ({
                date: '',
                red_flag: stg === 3,
                yellow_flag: stg === 2,
            })) : []
        }
        return indivTrip;
    }
    const fetchTripsStages = async () => {
        // const response: AxiosResponse<IndividualTripObjectAPIResponseType> =
        //   await client.get(`${ENDPOINTS.individualTrip}/?id=${sliderItemId}`)
        setTrip(creteSingleTrip(sliderItemId))

    }
    // const fetchTripTimeStamps = async () => {
    //     // const response: AxiosResponse<IndividualTripObjectAPIResponseType> =
    //     //   await client.get(`${ENDPOINTS.individualTrip}/?id=${sliderItemId}`)
    //     const response: AxiosResponse<any> = await client.get(
    //         `/events/get_events?trip_id=${sliderItemId}`
    //     )
    //     console.log("fetch time stamps", response)
    // }



    useEffect(() => {
        fetchTripsStages()

    }, [])


    // useEffect(() => {
    //     fetchTripTimeStamps()
    //     console.log("reloaded")
    // }, [sliderItemId])

    const mountMap = () => {
        setIsMapMount(!isMapMount);
        setIsCutQtyMount(false);
        setEmailQtyMount(false);
        setIsAssignDoorMount(false);
        setIsAssignTruckMount(false);
    }

    const mountCutQty = () => {
        setIsMapMount(false);
        setIsCutQtyMount(!isCutQtyMount);
        setEmailQtyMount(false);
        setIsAssignDoorMount(false);
        setIsAssignTruckMount(false);
    }

    const mountEmail = () => {
        setIsMapMount(false);
        setIsCutQtyMount(false);
        setEmailQtyMount(!isEmailMount);
        setIsAssignDoorMount(false);
        setIsAssignTruckMount(false);
    }

    const mountAssignDoor = () => {
        setIsMapMount(false);
        setIsCutQtyMount(false);
        setEmailQtyMount(false);
        setIsAssignDoorMount(!isAssignDoorMount);
        setIsAssignTruckMount(false);
    }

    const mountAssignTruck = () => {
        setIsMapMount(false);
        setIsCutQtyMount(false);
        setEmailQtyMount(false);
        setIsAssignDoorMount(false);
        setIsAssignTruckMount(!isAssignTruckMount);
    }

    const changeTripIndex = (ix: number) => {
        // setIsMapMount(false);
        setIsCutQtyMount(false);
        setEmailQtyMount(false);
        setIsAssignDoorMount(false);
        setIsAssignTruckMount(false);
        setSliderItemId(tableTrips[ix].id);
    }

    const indexOfNS = tripStage?.stage_values.indexOf(0);
    const DelayedIX = indexOfNS === 1 ? 0 : indexOfNS === 2 ? 1 : indexOfNS === 3 ? 2 : indexOfNS === 4 ? 3 : indexOfNS === 5 ? 4 : indexOfNS === -1 ? 5 : 0

    const isSecondShow: boolean = isMapMount || isCutQtyMount || isEmailMount || isAssignDoorMount || isAssignTruckMount;
    const tripIx = _.findIndex(tableTrips, (t) => t.id.toString() === sliderItemId.toString());
    const nextIx = tripIx + 1;
    const preIx = tripIx - 1;

    return (
        <SlideOver closeOnClick={() => setShowSlider(false)} zindex padding margin width={isSecondShow}>
            <div className='scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-white'>
                {trip !== null && (
                    <div className='flex'>
                        <div className={isMapMount ? `w-[50%]` : isSecondShow ? `w-[50%] right-0 duration-75 ease-in transition-all translate-x` : `w-full right-0 duration-200 ease-out transition-all translate-x`}>
                            <div className='flex bg-[#F7F9FC]  p-4  justify-center flex-col'>
                                <div className='flex w-full justify-between'>
                                    <span data-testid="prevBtn"
                                        className={`flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular cursor-pointer ${preIx < 0 ? 'pointer-events-none opacity-[0.6]' : ''}`}
                                        onClick={() => changeTripIndex(preIx)}>
                                        <img className='flex mx-1 w-[8px] h-[7px]' src={Previous} alt="previous" /> Prev
                                    </span>
                                    <span className='flex text-[#1C1C1C] text-[12px] font-poppins font-semibold'>Trip Details </span>
                                    <span data-testid="nextBtn"
                                        className={`flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular cursor-pointer ${nextIx >= tableTrips.length ? 'pointer-events-none opacity-[0.6]' : ''}`}
                                        onClick={() => changeTripIndex(nextIx)}>
                                        Next <img className='flex mx-1 w-[8px] h-[7px]' src={Next} alt="previous" />
                                    </span>
                                </div>
                                <hr data-testid="line" className='mt-2' />
                                <div className='mx-2'>
                                    <div className="flex items-center mt-4 tracking-wide  text-[#1C1C1C] text-[14px] font-poppins font-semibold">Trip ID: {selectedTrip?.trip_id}
                                        <div data-testid='tripStatusDiv' className={`text-center ml-2 w-20 p-[2px] rounded-[4px] capitalize font-poppins text-[12px] ${STATUS_COLOR_CHIP_CLASSES[tripStatus?.toLowerCase()]}`}>
                                            {tripStatus}
                                        </div>
                                    </div>
                                    <div className='flex grid grid-cols-1 gap-2'>
                                        <div className='flex mt-3 grid grid-cols-2 gap-x-1 gap-y-2'>
                                            <span data-testid='IDspan' className='font-poppins font-regular text-[12px] text-[#919191]'> ID </span>
                                            <span data-testid='selectedTripIDspan' className='text-[#1C1C1C] font-poppins font-regular text-[12px] '>{selectedTrip.id}</span>
                                        </div>
                                        <div className='flex grid grid-cols-2 gap-x-0 gap-y-2' >
                                            <span className='font-poppins font-regular text-[12px] text-[#919191]'> Source </span>
                                            <span className='text-[#1C1C1C] capitalize font-poppins font-regular text-[12px] '>{selectedTrip.source.toLowerCase()}</span>
                                        </div>
                                        <div className='flex grid grid-cols-2 gap-x-0 gap-y-2'>
                                            <span className='font-poppins font-regular text-[12px] text-[#919191]'> Destination </span>
                                            <span className='text-[#1C1C1C] capitalize font-poppins font-regular text-[12px] '>{selectedTrip.destination.toLowerCase()}</span>
                                        </div>
                                        <div className='flex grid grid-cols-2 gap-x-0 gap-y-2'>
                                            <span className='font-poppins font-regular text-[12px] text-[#919191]'> Load ID </span>
                                            <span className='text-[#1C1C1C] font-poppins font-regular text-[12px] '>{selectedTrip.load_id}
                                                <span className='mx-2'> | </span>
                                                <span className='font-poppins font-regular text-[12px] text-[#18749C] cursor-pointer' onClick={() => mountMap()}>
                                                    View Map
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='justify-start mt-4'>
                                {stages.map((stage, index) => (

                                    <Timeline key={stage.name} sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}>
                                        <TimelineItem sx={{ "& .MuiTimelineItem-root": { before: "none", after: "none" } }}>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{ background: 'white', boxShadow: 'none' }}>
                                                    {/*
                                                        tripStatus?.toLowerCase() === "delayed" && index === DelayedIX ?
                                                            <ErrorIcon sx={{ color: '#F01F1F' }} /> : index > DelayedIX ?
                                                                <FiberManualRecordIcon sx={{ color: 'gray' }} /> :
                                                                <CheckCircleIcon sx={{ color: '#05C97F' }} />
                                                    */}
                                                    {
                                                        tripStage.stage_values[index] === 3 ? <ErrorIcon sx={{ color: '#F01F1F' }} /> :
                                                            tripStage.stage_values[index] === 1 ? <CheckCircleIcon sx={{ color: '#05C97F' }} /> :
                                                                tripStage.stage_values[index] === 2 ? <ErrorIcon sx={{ color: '#F2B417' }} /> :
                                                                    <FiberManualRecordIcon sx={{ color: 'gray' }} />
                                                    }
                                                </TimelineDot>
                                                {index <= 4 && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent data-testid="stageContent" className={`mx-2 rounded-[4px] ${(tripStatus?.toLowerCase() === "delayed" && index === DelayedIX) ? "border border-solid border-[#F01F1F]" : "border border-solid border-[#EBEBEB]  "} `} sx={{ py: '12px', px: 2 }}>
                                                <div className='flex flex-col gap-2 w-full'>
                                                    <span className='flex w-full justify-between'>
                                                        <span className='font-poppins font-semibold text-[12px] text-[#1C1C1C]'>{stage.name}  </span>
                                                        <span className='align-center items-center font-poppins font-regular text-[12px]  text-[#AEAEAF] '>
                                                            {tripStage?.stage_ts[index] !== "" ? moment(tripStage.stage_ts[index]).format('MMM DD, YYYY') : ""}
                                                        </span>
                                                    </span>
                                                    <span className='flex w-full justify-between'>
                                                        <span className='  mt-1 font-poppins font-regular text-[12px] text-[#7E7E7E]'>
                                                            Shipment at this stage is
                                                            {/* ${tripStatus?.toLowerCase() === "delayed" && index === DelayedIX ? "text-[#F01F1F]" : " */}
                                                            <span data-testid="statustext" className={` ${tripStage.stage_values[index] === 3 && "Delayed" ? "text-[#F01F1F]" : ""} align-center items-center ml-1 font-poppins font-regular text-[12px] text-[#1C1C1C]} `}>
                                                                {/*
                                                                    index > DelayedIX ? "Not Started" :
                                                                        tripStatus?.toLowerCase() === "delayed" && index === DelayedIX ? "Delayed" :
                                                                            "On Time"
                                                                */}
                                                                {
                                                                    tripStage.stage_values[index] === 3 ? "Delayed" :
                                                                        tripStage.stage_values[index] === 2 ? "At Risk" :
                                                                            tripStage.stage_values[index] === 1 ? "On Time" :
                                                                                "Not Started"
                                                                }
                                                            </span>
                                                        </span>
                                                        {
                                                            tripStatus?.toLowerCase() === "delayed" && index === DelayedIX && tripStage?.recommendation !== "none" && tripStage?.recommendation !== "" &&
                                                            <span onClick={() => tripStage?.recommendation === "Email" ? mountEmail() : tripStage?.recommendation === "Cut Quantity" ? mountCutQty() : tripStage?.recommendation === "Call Yard" ? mountEmail() : tripStage?.recommendation === "Assign Door" ? mountAssignDoor() : tripStage?.recommendation === "Assign Truck" ? mountAssignTruck() : ""}
                                                                className='flex cursor-pointer mt-0.5 text-[14px]  align-center items-center font-poppins font-regular text-[#18749C] '>
                                                                {tripStage?.recommendation}
                                                                <img src={ArrowBack} alt="img" className='flex  ml-0.5 align-center items-center  w-[4px] h-[7px]' />
                                                            </span>
                                                        }
                                                    </span>
                                                    {tripStatus?.toLowerCase() === "delayed" && index === DelayedIX && tripStage.actiontaken && <div><span className='font-poppins font-medium text-[#05C97F] text-[12px] bg-[#05C97F14] text-[#7E7E7E]  p-1'>Action Taken</span></div>}
                                                </div>

                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                ))}

                            </div>
                        </div>
                        {
                            isMapMount && (
                                <div className='w-[60%]' >
                                    <MapSlider key={sliderItemId}
                                        mountMap={mountMap}
                                        sliderItemId={sliderItemId} selectedTrip={selectedTrip}
                                        stages={stages} tripStage={tripStage}
                                    />
                                </div>
                            )
                        }
                        {
                            isCutQtyMount && (
                                <div className='w-[50%] bg-gray-100 bg-no-repeat shadow-[-3px_0px_16px_0px_rgb(0,0,0,0.1)]'>
                                    <UserActionCutQty mountCutQty={mountCutQty} ActionTaken={ActionTaken} />
                                </div>
                            )
                        }
                        {
                            isEmailMount && (
                                <div className='w-[50%] bg-gray-100 bg-no-repeat shadow-[-3px_0px_16px_0px_rgb(0,0,0,0.1)]'>
                                    <UserActionMail mountEmail={mountEmail} ActionTaken={ActionTaken} />
                                </div>
                            )
                        }
                        {
                            isAssignDoorMount && (
                                <div className='w-[50%] bg-gray-100 bg-no-repeat shadow-[-3px_0px_16px_0px_rgb(0,0,0,0.1)]'>
                                    <UserActionAssignDoor mountAssignDoor={mountAssignDoor} ActionTaken={ActionTaken} />
                                </div>
                            )
                        }
                        {
                            isAssignTruckMount && (
                                <div className='w-[50%] bg-gray-100 bg-no-repeat shadow-[-3px_0px_16px_0px_rgb(0,0,0,0.1)]'>
                                    <UserActionAssignTruck mountAssignTruck={mountAssignTruck} ActionTaken={ActionTaken} />
                                </div>
                            )
                        }
                    </div>
                )}
            </div>
        </SlideOver >
    )
}

export default TripsSlider
