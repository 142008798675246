/* eslint-disable */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable security/detect-object-injection */
import { useState, useEffect } from 'react'
import _ from 'lodash'
// import 'leaflet/dist/leaflet.css'
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
} from 'react-leaflet'
import { Icon } from 'leaflet'
import client from "clientfast"
import { AxiosResponse } from 'axios'
import {
  InboundObject, StageObject, TripStagesObject
} from 'pages/dashboard/types'
import { MapProps, LocationdetailsProps, FetchMapDetailsProps } from "./types"
import circleIcon from '../../assets/images/Group-icon.png'
import mapMarker from '../../assets/images/map-marker.png'
import RoutingMachine from './RoutineMachine'
import truckLogo from "../../assets/images/Icon-truck.png"
import ArrowBack from '../../assets/images/arrowback.png'
import currentStageICon from '../../assets/images/currentStageIcon.png'

const truck = new Icon({
  iconUrl: currentStageICon,
  iconSize: [110, 110],
})

const pin = new Icon({
  iconUrl: circleIcon,
  iconSize: [20, 20],
})
const back = new Icon({
  iconUrl: ArrowBack,
  iconSize: [20, 20],
})

function MapSlider({
  sliderItemId,
  selectedTrip,
  mountMap,
  stages,
  tripStage

}: {
  sliderItemId: number
  selectedTrip: InboundObject
  mountMap: () => void;
  stages: StageObject[]
  tripStage: TripStagesObject
}) {
  const [tripCoordinates, setTripCoordinates] = useState<MapProps[]>([])
  const [locationPoints, setLocationPoints] = useState<number[][]>([])
  const transfromLocations = (data: FetchMapDetailsProps) => {
    const locationDetails = _.map(data, (value, key) => ({
      status: key,
      name: value.name,
      cordinates: value.coordinates,
      date: ""
    }))
    return locationDetails;
  }

  const fetchCoordinates = async () => {
    const response: AxiosResponse<FetchMapDetailsProps> = await client.get(
      `/map/get_locations?trip_id=${sliderItemId}`
    )
    const tc = transfromLocations(response.data);
    setTripCoordinates(tc);
    setLocationPoints(_.map(tc, (val) => val.cordinates));
  }

  useEffect(() => {
    fetchCoordinates()
  }, [])

  const stageIndex = tripStage.stage_values.indexOf(0)
  // console.log(stageIndex, "stage")
  return (
    <div>
      {selectedTrip !== null && (
        <div className=' h-[100vh] '>
          <div onClick={() => mountMap()} className='flex cursor-pointer absolute p-2 z-[1000] bg-white top-[15px]  right-[2%] rounded-md drop-shadow-[0_3px_6px_rgba(0, 0, 0, 0.16)] flex items-center align-center text-[#18749C] text-[12px] font-poppins font-regular  '>
            Close <img className='flex mx-1 w-[4px] h-[7px]' src={ArrowBack} alt="close" />
          </div>
          {tripCoordinates && tripCoordinates.length > 0 && locationPoints && locationPoints.length > 0 && (
            <MapContainer
              className=' h-[100vh]'
              zoom={13}
            >
              <TileLayer
                attribution='Google Maps'
                url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
              />
              {tripCoordinates.map((stage: MapProps, index: number) => (
                < Marker
                  key={tripCoordinates[index].name}
                  position={
                    [
                      tripCoordinates[index].cordinates[0],
                      tripCoordinates[index].cordinates[1],
                    ]}
                  // icon={stage.date !== '' ? truck : pin}
                  icon={(stageIndex === 1 || stageIndex === 2 || stageIndex === 3) && index === 0 ? truck :
                    stageIndex === 4 && index === 1 ? truck : (stageIndex === 5 || stageIndex === 6 || stageIndex === -1) && index === 2 ? truck : pin}
                // icon={pin}
                >
                  <Tooltip permanent>
                    <div className='w-[155px]'>
                      <div className='p-[5px] border-dashed border-b-[1px] flex justify-start text-[14px] text-[#18749C]  opacity-100'>
                        <img className='h-[13px]' src={truckLogo} alt='truckLogo' /> <div className='-mt-[2px] text-xs pl-[4px]'> {stage.status}</div>
                      </div>
                      < div className='p-[5px] w-20 h-9'>
                        <div>
                          <div className='font-semibold text-[#1C1C1C] opacity-100'>{tripCoordinates[index].name}</div>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
              ))}

              <RoutingMachine waypoints={locationPoints} />
            </MapContainer>)}

        </div>
      )
      }
    </div >
  )
}

export default MapSlider
