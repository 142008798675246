/* eslint-disable */
import { useState } from 'react';
import { setUser } from "store/slice/userSlice";
import { useAppSelector, useAppDispatch } from 'hooks/store-hooks';
import { logoutUser } from 'store/slice/authSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

import LogoutIcon from '@mui/icons-material/Logout';
import LinkIcon from '@mui/icons-material/Link';
import Notification from "../assets/images/notification-10.png"

import AppModal from './AppModal';
import Scai from "../assets/images/Scailogo.png"



function GeneralHeader() {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [appModalShow, setAppModalShow] = useState<boolean>(false)


    const { loggedIn, } = useAppSelector((state) => state.user);
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const navigate = useNavigate()

    const dispatch = useAppDispatch();

    const routechange = () => {
        const path = '/platform'
        navigate(path)
    }

    const logoutroute = () => {
        dispatch(logoutUser())
        dispatch(setUser(false));
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };
    const id = open ? 'simple-popover' : undefined;

    return (

        <div className="bg-white px-4 py-3 flex w-full">
            <div className="flex items-start w-[50%]">
                <div onClick={routechange} className="flex items-start text-lg text-[#1C1C1C] font-poppins font-semibold tracking-normal cursor-pointer"><img className='h-[40px] w-[94.52px]' src={Scai} alt="" /></div>
            </div>
            <div className="flex justify-end w-[50%]">
                <div className="float-right items-center flex gap-4">
                    <div className="cursor-pointer">
                        <LinkIcon sx={{ color: "#003668" }} onClick={() => setAppModalShow(true)} />
                        {
                            appModalShow &&
                            <AppModal setAppModalShow={setAppModalShow} appModalShow={appModalShow} />
                        }
                    </div>
                    <div className='flex items-center cursor-pointer'><img className="" src={Notification} alt="Notification" /></div>
                    <div className="cursor-pointer">
                        {loggedIn &&
                            <div>

                                <AccountCircleIcon sx={{ color: "#003668" }} onClick={handleClick} />
                                {
                                    open &&
                                    <Popover
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 40,
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div onClick={logoutroute} className="flex p-4 cursor-pointer justify-center align-center items-center"><LogoutIcon fontSize='small' sx={{ marginRight: 1 }} /><span>Logout</span></div>
                                    </Popover>
                                }

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralHeader